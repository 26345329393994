import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navigation.css";
import { useState, useEffect } from "react";
import cross from "../../../assets/images/Huge-icon.png";
import arrow from "../../../assets/images/arrow_2.png";

const Navigation = ({ show, windowWidth, toggle }) => {
  console.log("🚀 ~ Navigation ~ show, windowWidth:", show, windowWidth);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const topScroll = () => {
    console.log("ksfkokjsdfkjhsjdfhbj");
    window.scrollTo(0, 0);
  };
  const isLoginPage = location.pathname === "/login";

  return (
    <>
      <nav className={`navigation ${scrolled ? "scrolled" : ""}`}>
        {show && windowWidth < 768 ? (
          <ul className="toggle_ul">
            <div className="menu_bar_logo">
              <img
                src={cross}
                style={{ width: "50px", stroke: "white" }}
                role="button"
                onClick={toggle}
              />
            </div>
            <div className="small_li">
              <li onClick={topScroll}>
                <Link to="/services" className="line">
                  Services{" "}
                </Link>
              </li>
              <li onClick={topScroll}>
                <Link to="/about">About</Link>
              </li>
              <li onClick={topScroll}>
                <Link to="/story">Our Story</Link>
              </li>
              <div className="btn_div">
                <Link to="/career">Choose language</Link>
                <Link to={isLoginPage ? "/register" : "/login"}>
                  {isLoginPage ? "Register" : "Login"}
                </Link>
                
              </div>
            </div>
          </ul>
        ) : (
          <ul className="big_screen">
            <li onClick={topScroll} className="non-essential-link">
              <Link to="/services">Services</Link>
            </li>
            <li onClick={topScroll} className="non-essential-link">
              <Link to="/about">About</Link>
            </li>
            <li onClick={topScroll} className="non-essential-link">
              <Link to="/story">Our Story</Link>
            </li>
            <li onClick={topScroll} className="non-essential-link">
              <Link to="/career" className="button-link">
                Choose language
              </Link>
            </li>
            <li className="non-essential-link">
            <Link to={isLoginPage ? "/register" : "/login"} className="button-link">
                {isLoginPage ? "Register" : "Login"}
              </Link>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
};

export default Navigation;
