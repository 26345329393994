import React from 'react';
import { useParams } from 'react-router-dom';
import './RoleDetails.css';
import Footer from '../../../../shared/components/Footer/Footer';
import HeaderContent from '../../../../shared/components/Header/HeaderContent';

const RoleDetail = () => {
  const { id } = useParams();

  const storyData = {
    1: {
      title: "Telecaller Position",
      description: `
        <h2>Job Description:</h2>
        <p>We are interested in hiring a dedicated and hardworking individual as a Telecaller to join our Sales Department.</p>
        <p>Your main goal is to boost our sales by reaching out to the existing customers as well as potential customers. As a Telecaller, you are responsible for handling sales over the phone entirely. You are also responsible for solving questions in regard to the product or service provided by the company.</p>
        <p>In addition to this, you should collect desired information from the clients and maintain healthy relations with them. You are required to understand the customer’s requirements and demands and close the sales deal efficiently. You are also required to write down important information provided by the customer and follow up with them on a regular basis.</p>
        <p>As an ideal candidate, you should be an excellent communicator along with convincing abilities. Great report writing skills, amazing negotiation skills, and phenomenal telephone etiquette are essential for this position.</p>
        <p>If you are ready to take up these duties and responsibilities of Telecaller, then apply right away. We will love to meet you.</p>

        <h2>Responsibilities:</h2>
        <ul>
          <li>Answering phones and explaining the product and services offered by the company.</li>
          <li>Contacting existing customers as well as prospective customers using scripts.</li>
          <li>Obtaining customer information and other relevant data.</li>
          <li>Asking questions to the customer and understanding their specifications.</li>
          <li>Resolving queries and issues related to the products and services.</li>
          <li>Making recordings of all the sales phone calls and sales deals.</li>
          <li>Taking and processing product orders in a professional manner.</li>
          <li>Maintaining the database of the customers on a regular basis.</li>
          <li>Suggesting solutions based on customer’s needs and requirements.</li>
        </ul>

        <h2>Requirements:</h2>
        <ul>
          <li>High school diploma or equivalent.</li>
          <li>Work experience as a Telecaller, TeleMarketer, or a similar role in the Sales Department.</li>
          <li>Professional certification in sales and marketing will be an advantage.</li>
          <li>Great interpersonal skills.</li>
          <li>Exceptional oral and written communication skills.</li>
          <li>Strong organizational skills.</li>
          <li>Ability to work in a team or individually as and when required.</li>
          <li>Ability to manage and handle multiple tasks.</li>
          <li>Outstanding problem-solving skills.</li>
          <li>Exceptional attention to detail.</li>
          <li>Hard-working individual.</li>
          <li>Good time management abilities.</li>
          <li>Strong decision-making skills.</li>
          <li>Ability to tolerate stress and pressure.</li>
        </ul>

        <p><strong>Salary:</strong> 8k to 15k</p>
      `,
    },
    2: {
      title: "Data Entry Operator Position",
      description: `
        <h2>Job Description:</h2>
        <p>We are looking for a Data Entry Operator to update and maintain information on our company databases and computer systems.</p>
        <p>Data Entry Operator responsibilities include collecting and entering data in databases and maintaining accurate records of valuable company information. Our ideal candidate has essential data entry skills, like fast typing with an eye for detail and familiarity with spreadsheets and online forms. You will work with a data team and Data Manager. Previous experience as a Data Entry Clerk or similar position will be considered an advantage.</p>
        <p>Ultimately, a successful Data Entry Operator will be responsible for maintaining accurate, up-to-date and usable information in our systems.</p>

        <h2>Responsibilities:</h2>
        <ul>
          <li>Insert customer and account data by inputting text-based and numerical information from source documents within time limits.</li>
          <li>Compile, verify accuracy and sort information according to priorities to prepare source data for computer entry.</li>
          <li>Review data for deficiencies or errors, correct any incompatibilities if possible and check output.</li>
          <li>Research and obtain further information for incomplete documents.</li>
          <li>Apply data program techniques and procedures.</li>
          <li>Generate reports, store completed work in designated locations and perform backup operations.</li>
          <li>Scan documents and print files, when needed.</li>
          <li>Keep information confidential.</li>
          <li>Respond to queries for information and access relevant files.</li>
          <li>Comply with data integrity and security policies.</li>
          <li>Ensure proper use of office equipment and address any malfunctions.</li>
        </ul>

        <h2>Requirements:</h2>
        <ul>
          <li>Proven data entry work experience, as a Data Entry Operator or Office Clerk.</li>
          <li>Experience with MS Office and data programs.</li>
          <li>Familiarity with administrative duties.</li>
          <li>Experience using office equipment, like fax machine and scanner.</li>
          <li>Typing speed and accuracy.</li>
          <li>Excellent knowledge of correct spelling, grammar and punctuation.</li>
          <li>Attention to detail.</li>
          <li>Confidentiality.</li>
          <li>Organization skills, with an ability to stay focused on assigned tasks.</li>
        </ul>

        <p><strong>Salary:</strong> 12k to 18k</p>
      `,
    },
    3: {
        title: "MERN Stack Developer",
        description: `
          <h2>Job Description:</h2>
          <p>We are seeking a skilled and experienced MERN (MongoDB, Express.js, React, Node.js) Stack Developer to join our dynamic team.</p>
          <p>As a MERN Stack Developer, you will be responsible for designing, implementing, and maintaining full-stack applications.</p>
          <p>You will collaborate with cross-functional teams to deliver high-quality software solutions.</p>
  
          <h2>Responsibilities:</h2>
           <ul> 
           <li>Responsible for solving complex technical issues.</li>
           <li>Collaboration skills to support business goals</li>
           <li>Create, test, and deploy robust products that are fast, scalable, and responsive</li>
           <li>Create and maintain massive, complicated databases, both relational and non-relational</li>
           <li>As needed, establish, enhance, and blend development environment tools and software</li>
          </ul>
      
  
          <h2>Requirements:</h2>
          <ul>
          <li>Expertise and experience in the four main technologies – MongoDB/MySQL, ExpressJS, React, and NodeJS</li>
          <li>Knowledge of Next Js, Typescript and Loopback.io would be an added advantage.</li>
          <li>Knowledge of HTML/CSS, SCSS, jQuery, OOPS, Git, and design framework, web services, JSON, AJAX, security, and frontend CSS frameworks like Bootstrap, Tailwind, etc.</li><li>High-quality programming skills</li><li>Experience with redux and its middleware thunk or saga</li>
          <li>Material UI or Prime UI</li><li>Experience in Microservices, creating RESTful services with Node.js.</li>
          <li>Experience in any of the following ORM (Prisma, Sequelize, TypeORM, Mongoose)</li>
          <li>Experience in third party libraries such as payment gateways, chat, sms, push notifications, etc.</li>
          <li>Experience in application architecture, server management, cross-browser compatibility, responsive design, and website performance</li><li>Understanding of DB architecture design and programming templates, Agile methodologies, client-side and server-side procedures</li>
          <li>Familiarity with package manager-npm</li><li>Write well-designed, testable, efficient code.</li>
          <li>Server management skills (AWS services like EC2, S3, Route 53, RDS, Lambada,etc OR Digital Ocean services like CI/CD, Docker, Droplet, etc OR Azure DevOps Server)</li>
          </ul>
  
          <p><strong>Salary:</strong> 45k to 50k</p>
        `,
      },
  };

  const story = storyData[id];

  if (!story) {
    return <div>Story not found</div>;
  }

  return (
    <> 
      <HeaderContent/> 
      <div className="rl-detail-container">
        <div className="rl-detail-content">
          <div className="rl-detail-text">
            <h1 className="rl-title" dangerouslySetInnerHTML={{ __html: story.title }}></h1>
            <div dangerouslySetInnerHTML={{ __html: story.description }}></div>
          </div>
          <div class="apply-now-container">
          <button class="apply-now-btn">Apply Now</button>
        </div>
        </div>
        
      </div>
      <Footer/>
    </>
  );
};

export default RoleDetail;
