import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MatrimonyLogin.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import img1 from "../../../../assets/images/close.webp";
import { login } from '../../../../AuthService';

const MatrimonyLogin = () => {
    const navigate = useNavigate();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const initialValues = {
    email: '',
    password: '', // Add password to initialValues
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().required('Required'), // Add password validation
  });

  const forgotPasswordInitialValues = {
    email: '',
  };

  const forgotPasswordValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
  });

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      await login(values.email, values.password);
      navigate('/matrimonial-form');
    } catch (error) {
      setStatus({ error: error.message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <section className="ml-login-section">
        <div className="ml-login-container">
          <div className="ml-login-form">
            <h4>Welcome !<br />Enter your details to login</h4>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, status }) => (
                <Form className="form">
                  <div className="ml-login-form-group">
                    <Field type="text" id="email" name="email" placeholder="Email" />
                    <ErrorMessage name="email" component="div" className="error-message" />
                  </div>
                  <div className="ml-login-form-group">
                    <Field type="password" id="password" name="password" placeholder="Password" />
                    <ErrorMessage name="password" component="div" className="error-message" />
                  </div>
                  <div className="ml-additional-options">
                    <label className='forgot-b' onClick={() => setShowForgotPassword(true)}>Forgot Password?</label>
                  </div>
                  <div className="ml-login-form-group">
                    <button type="submit" className="login-button" disabled={isSubmitting}>
                      {isSubmitting ? 'Logging in...' : 'Login'}
                    </button>
                  </div>
                  {status && status.error && <div className="error-message">{status.error}</div>}
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {showForgotPassword && (
          <div className='ml-forget-popup-div'>
            <div className="ml-popup-overlay">
              <div className="ml-popup-content">
                <img src={img1} alt='' className='close-icon' onClick={() => setShowForgotPassword(false)} />
                <h3>Forgot Password</h3>
                <Formik
                  initialValues={forgotPasswordInitialValues}
                  validationSchema={forgotPasswordValidationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, status }) => (
                    <Form>
                      <div className="ml-form-group-div">
                        <Field type="email" name="email" placeholder="Enter your email" className="email-input-field" />
                        <ErrorMessage name="email" component="div" className="error-message" />
                      </div>
                      {status && status.success && <div className="success-message">The verification link sent to your email!</div>}
                      <div className="ml-form-group-div">
                        <button type="submit" className="ml-submit-button" disabled={isSubmitting}>
                          {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default MatrimonyLogin;
