// Dashboard.js

import React from 'react';

const Dashboard = () => {
  return (
    <div>
      <h2>Dashboard</h2>
      <p>Welcome! You are logged in.</p>
    </div>
  );
}

export default Dashboard;
