// authService.js
import ApiService from './ApiService';

const login = async (email, password) => {
  try {
    const response = await ApiService.post('user/login', { email, password });
    localStorage.setItem('token', response.token);
    localStorage.setItem('useremail', response.user.email);

    console.log("res",response)
    return response.token;
  } catch (error) {
    throw error;
  }
};

const createForm = async (formData) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
  
      const response = await ApiService.post('user/create-form', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('useremail');

};

const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

export { login, createForm, logout, isAuthenticated };
