import React from 'react';
import { useLocation } from 'react-router-dom';
import './Searchresult.css';
import Footer from '../../../../shared/components/Footer/Footer';
import HeaderContent from '../../../../shared/components/Header/HeaderContent';
import Image1 from '../../../../assets/images/female.jpg';
import Image2 from '../../../../assets/images/male.png';

const SearchResult = () => {
  const location = useLocation();
  const { searchCriteria } = location.state;

  const data = [
    {
      user_id: "577324",
      guardian: "Profile created by Parent / Guardian Online few mins ago",
      img: Image2,
      age: "30, 5'2\"",
      height: "5'2\"",
      gender: "female",
      religion: "Christianity",
      mother_tongue: "English",
      community: "Not Specified",
      location: "New York",
      education: "Bachelor's Degree",
      profession: "Software Engineer",
      description: "Hello, I'm glad you are interested in my daughter's profile. She is currently living in New York. With hard work and dedication, she has built a successful career..."
    },
    {
      user_id: "577325",
      guardian: "Profile created by Sibling Online few mins ago",
      img: Image1,
      age: "28, 5'8\"",
      height: "5'8\"",
      gender: "male",
      religion: "Hinduism",
      mother_tongue: "Hindi",
      community: "Not Specified",
      location: "San Francisco",
      education: "Master's Degree",
      profession: "Data Scientist",
      description: "Hi, I am creating this profile for my brother. He is currently living in San Francisco and is working as a Data Scientist..."
    },
    {
      user_id: "577326",
      guardian: "Profile created by Friend Online few mins ago",
      img: Image2,
      age: "32, 5'6\"",
      height: "5'6\"",
      gender: "female",
      religion: "Islam",
      mother_tongue: "Arabic",
      community: "Not Specified",
      location: "Los Angeles",
      education: "PhD",
      profession: "Professor",
      description: "Assalamu Alaikum, I am creating this profile for my friend. She is a professor in Los Angeles..."
    },
  ];

  const filteredData = data.filter(profile => {
    const matchesAge = profile.age && parseInt(profile.age.split(',')[0]) >= parseInt(searchCriteria.ageFrom) && parseInt(profile.age.split(',')[0]) <= parseInt(searchCriteria.ageTo);
    const matchesHeight = profile.height && parseInt(profile.height.replace(/[^0-9]/g, '')) >= parseInt(searchCriteria.height);
    const matchesGender = profile.gender === searchCriteria.gender;
    const matchesMaritalStatus = profile.marital_status === searchCriteria.maritalStatus;
    const matchesReligion = profile.religion === searchCriteria.religion;
    const matchesMotherTongue = profile.mother_tongue === searchCriteria.motherTongue;
    const matchesCountry = profile.location && profile.location.toLowerCase().includes(searchCriteria.country.toLowerCase());

    return (
      matchesAge ||
      matchesHeight ||
      matchesGender ||
      matchesMaritalStatus ||
      matchesReligion ||
      matchesMotherTongue ||
      matchesCountry
    );
  });

  return (
    <>
      <HeaderContent />
      <section className='result-page-section'>
        <div className='container'>
          <div className='filter-div'>
            <h2 className='filter-title'>Refine Search</h2>
            <div className='filter-sub-div'>
              <label className='filter-main-label'>Recently joined</label>
              <div className='input-content-div'>
                <div>
                  <input type="radio" value="Within a day" className='filter-input' />
                  <label htmlFor="withinDay">Within a day</label>
                </div>
                <div>
                  <input type="radio" value="Within a week" className='filter-input' />
                  <label htmlFor="withinWeek">Within a week</label>
                </div>
                <div>
                  <input type="radio" value="Within a month" className='filter-input' />
                  <label htmlFor="withinMonth">Within a month</label>
                </div>
              </div>

            </div>
            <div>
              <label className='filter-main-label'>Active members</label>
              <div className='input-content-div'>
                <div>
                  <input type="radio" value="Within a day" className='filter-input' />
                  <label htmlFor="withinDay">Within a day</label>
                </div>
                <div>
                  <input type="radio" value="Within a week" className='filter-input' />
                  <label htmlFor="withinWeek">Within a week</label>
                </div>
                <div>
                  <input type="radio" value="Within a month" className='filter-input' />
                  <label htmlFor="withinMonth">Within a month</label>
                </div>
              </div>
            </div>

          </div>
          <div className='profile-boxes'>
    <h2 className='search-title'>Your Results</h2>
    {filteredData.map((person, index) => ( // Corrected map function syntax
        <div key={index} className='main-box-profile'>
            <p className='userId-title'>{person.user_id}</p>
            <p className='guardian-name'>{person.guardian}</p>
            <div className='sub-profile-box'>
                <div className='profile-img'>
                    <img src={person.img} alt="" width={"100%"} />
                </div>
                <div>
                    <div className='profile-content-div'>
                        <div className='profile-content'>
                            <p>Age/Height: {person.age}</p>
                            <p>Religion: {person.religion}</p>
                            <p>Mother Tongue: {person.mother_tongue}</p>
                            <p>Community: {person.community}</p>
                            <p>Location: {person.location}</p>
                            <p>Education: {person.education}</p>
                            <p>Profession: {person.profession}</p>
                        </div>
                        <div className='profile-selection'>
                            <h3>Like their Profile</h3>
                            <button className='interested-btn'>Yes, I'm interested</button>
                            <div className='maybe-and-no-btn'>
                                <button className='maybe-btn'>Maybe</button>
                                <button className='no-btn'>No</button>
                            </div>
                        </div>
                    </div>
                    <p>{person.description}</p>
                </div>
            </div>
        </div>
    ))}
</div>

        </div>
      </section>
      <Footer />
    </>
  );
};

export default SearchResult;










// import React from 'react';
// import "./Searchresult.css"
// import Footer from "../../../../shared/components/Footer/Footer"
// import HeaderContent from "../../../../shared/components/Header/HeaderContent"
// import Image1 from '../../../../assets/images/female.jpg';

// const SearchResult = () => {

//   const data = [
//     {
//       user_id: "577324",
//       guardian: "Profile created by Parent / Guardian Online few mins ago",
//       img: Image1,
//       age: "30, 5'2",
//       religion: "Christianity",
//       mother_tongue: "English",
//       community: "Not Specified",
//       location: "New York",
//       education: "Bachelor's Degree",
//       profession: "Software Engineer",
//       description: "Hello, I'm glad you are interested in my daughter's profile. She is currently living in Gurgaon Division. With hard work and d... "
//     },
//     {
//       user_id: "577324",
//       guardian: "Profile created by Parent / Guardian Online few mins ago",
//       img: Image1,
//       age: "30, 5'2",
//       religion: "Christianity",
//       mother_tongue: "English",
//       community: "Not Specified",
//       location: "New York",
//       education: "Bachelor's Degree",
//       profession: "Software Engineer",
//       description: "Hello, I'm glad you are interested in my daughter's profile. She is currently living in Gurgaon Division. With hard work and d... "
//     },
//     {
//       user_id: "577324",
//       guardian: "Profile created by Parent / Guardian Online few mins ago",
//       img: Image1,
//       age: "30, 5'2",
//       religion: "Christianity",
//       mother_tongue: "English",
//       community: "Not Specified",
//       location: "New York",
//       education: "Bachelor's Degree",
//       profession: "Software Engineer",
//       description: "Hello, I'm glad you are interested in my daughter's profile. She is currently living in Gurgaon Division. With hard work and d... "
//     }
//   ]
//   return (
//     <>
//       <HeaderContent />
//       <section className='result-page-section'>
//         <div className='container'>
//           <div className='filter-div'>
//             <h2 className='filter-title'>Refine Search</h2>
//             <div className='filter-sub-div'>
//               <label className='filter-main-label'>Recently joined</label>
//               <div className='input-content-div'>
//                 <div>
//                   <input type="radio" value="Within a day" className='filter-input' />
//                   <label htmlFor="withinDay">Within a day</label>
//                 </div>
//                 <div>
//                   <input type="radio" value="Within a week" className='filter-input' />
//                   <label htmlFor="withinWeek">Within a week</label>
//                 </div>
//                 <div>
//                   <input type="radio" value="Within a month" className='filter-input' />
//                   <label htmlFor="withinMonth">Within a month</label>
//                 </div>
//               </div>

//             </div>
//             <div>
//               <label className='filter-main-label'>Active members</label>
//               <div className='input-content-div'>
//                 <div>
//                   <input type="radio" value="Within a day" className='filter-input' />
//                   <label htmlFor="withinDay">Within a day</label>
//                 </div>
//                 <div>
//                   <input type="radio" value="Within a week" className='filter-input' />
//                   <label htmlFor="withinWeek">Within a week</label>
//                 </div>
//                 <div>
//                   <input type="radio" value="Within a month" className='filter-input' />
//                   <label htmlFor="withinMonth">Within a month</label>
//                 </div>
//               </div>
//             </div>

//           </div>
//           <div className='profile-boxes'>
//             <h2 className='search-title'>Your Results</h2>
//             {data.map((person, index) => (

//               <div key={index} className='main-box-profile' >
//                 <p className='userId-title'>{person.user_id}</p>
//                 <p className='guardian-name'>{person.guardian}</p>
//                 <div className='sub-profile-box'>
//                   <div className='profile-img'>
//                     <img src={person.img} alt="" />
//                   </div>
//                   <div>
//                     <div className='profile-content-div'>
//                       <div className='profile-content'>
//                         <p>Age/Height: {person.age}</p>
//                         <p>Religion: {person.religion}</p>
//                         <p>Mother Tongue: {person.mother_tongue}</p>
//                         <p>Community: {person.community}</p>
//                         <p>Location: {person.location}</p>
//                         <p>Education: {person.education}</p>
//                         <p>Profession: {person.profession}</p>
//                       </div>
//                       <div className='profile-selection'>
//                         <h3>Like her Profile</h3>
//                         <button className='interested-btn'>Yes, I'm interested</button>
//                         <div className='maybe-and-no-btn'>
//                           <button className='maybe-btn'>Maybe</button>
//                           <button className='no-btn'>No</button>
//                         </div>
//                       </div>

//                     </div>
//                     <p>{person.description}</p>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section >
//       <Footer />
//     </>
//   );

// }

// export default SearchResult;

