import React from 'react';
import './Footer.css';
import Logo from '../../../assets/images/footer-logo.png';
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="footer-logo">
            <img src={Logo} alt="Sangam Sahithi" />
          </div>
          <div className="footer-titles">
            <p>Awards And Registration</p>
            <p>Contact Us</p>
            <p>hr.sangamsahithi.com</p>
          </div>
          <div className="footer-address-container">
            <div className="footer-address">
              <h4>India</h4>
              <p>F-160, First Floor, Corporate Wing, Fantasia Business Park, sector 30B, Vashi Navi Mumbai, Maharashtra 400703</p>
            </div>
            <div className="footer-address">
              <h4>Registered Office</h4>
              <p>Suite 12, 32-33 Elmwood Ave, Co. Antrim, Belfast, BT96AZ, United Kingdom</p>
            </div>
          </div>
        </div>
        <div className="footer-menu-container">
          <div className="footer-menu">
            <h3>Privacy & You</h3>
            <ul>
              
            <li><Link to="/terms">Terms of Use</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/online">Be Safe Online</Link></li>
              <li><Link to="/report">Report Misuse</Link></li>
            </ul>
          </div>
          <div className="footer-menu">
            <h3>Need Help</h3>
            <ul>
              <li><Link to="/login">Member Login</Link></li>
              <li><Link to="/register">Sign Up</Link></li>
              <li><Link to="/search">Partner Search</Link></li>
              <li><Link to="/career">Career</Link></li>
              <li><Link to="/customer-support">Customer Support</Link></li>
            </ul>
          </div>
          <div className="footer-menu">
            <h3>More</h3>
            <ul>
              <li><Link to="/story">Success Stories</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
