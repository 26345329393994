import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import Navigation from "../Navigation/Navigation";
import Logo from "../../../assets/images/contact-logo.png";
import { Container } from "react-bootstrap";
import menuBar from "../../../assets/images/menu-bar.png";

const HeaderContent = () => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [scrolled, setScrolled] = useState(false);

  const windowWidth = window.screen.width;
  console.log("🚀 ~ HeaderContent ~ windowWidth:", windowWidth);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${scrolled ? "scrolled" : ""} header-content sticky-header`}
    >
      <Container>
        <div className="nav-wrapper">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Sangam Sahithi" />
            </Link>
          </div>
          <Navigation toggle={toggle} show={show} windowWidth={windowWidth} />
          <div>
            <img
              className="menu_bar"
              src={menuBar}
              role="button"
              onClick={toggle}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderContent;
