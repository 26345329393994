import React, { useState } from 'react';
import './Login.css';
import Footer from '../../../../shared/components/Footer/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import img1 from "../../../../assets/images/close.webp"
import HeaderContent from '../../../../shared/components/Header/HeaderContent';

const LoginSection = () => {

  const [showForgotPassword, setShowForgotPassword] = useState(false);

    const initialValues = {
      email: '',
    };

    const validationSchema = Yup.object({
      email: Yup.string().email('Invalid email format').required('Required'),
    });

    const onSubmit = (values, { setSubmitting, setStatus }) => {
      setTimeout(() => {
        setSubmitting(false);
        setStatus({ success: true });
      }, 2000);
    };

  return (
    <>
    <HeaderContent/>
      <section className="login-section">
        
        <div className="login-container">
          <div className="login-form">
            <h4>Welcome back!<br />Enter your email id to login</h4>
            <form className='form'>
              <div className="login-form-group">
                <input type="text" id="loginid" name="loginid" placeholder="Email" />
              </div>
              <div className="login-form-group">
                <input type="password" id="password" name="password" placeholder="Password" />
              </div>
              <div className="additional-options">
                <label>
                  <input type="checkbox" /> Keep me logged in
                </label>
                <label className='forgot-b' onClick={() => setShowForgotPassword(true)}>Forgot Password?</label>
              </div>
              <div className="login-form-group">
                <button type="submit" className="login-button">Login</button>
              </div>
              <div className="divider">
                <span>OR</span>
              </div>
              <button className="continue-button">Continue with Mobile</button>
            </form>

          </div>
        </div>
        <div className='forget-popup-div'>

        
        { showForgotPassword ? (<>
          <div className="popup-overlay">
            <div className="popup-content">
              <img src={img1} alt='' className='close-icon'  onClick={() => setShowForgotPassword(false)}/>
              <h3>Forgot Password</h3>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, status }) => (
                  <Form>
                    <div className="form-group-div">
                      <Field type="email" name="email" placeholder="Enter your email" className="email-input-field" />
                      <ErrorMessage name="email" component="div" className="error-message" />
                    </div>
                    {status && status.success && <div className="success-message">The verification link sent to your email!</div>}
                    <div className="form-group-div">
                      <button type="submit" className="submit-button" disabled={isSubmitting}>Submit</button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div></>) : ""
      }
      </div>
      </section>
      <Footer />
     
    </>
  );
};

export default LoginSection;
